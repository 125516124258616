<template>
  <default-layout :hide-header="true">
    <div class="welcome-page">
      <p class="w-title">{{ $t('insurance') }}</p>
      <!-- <the-stories/> -->
      <div class="banner-slider-wrap redesign">
        <template v-if="!bannerLoading">
          <VueSlickCarousel v-bind="options" v-if="banner">
            <section class="banner" v-for="(item, index) in banner" :key="index" :style="{'background': `url('${item.image}')`,}">
              <div>
                <div v-if="item.hasLinkButton == 1" @click="mainBannerClicked(item.buttonLink)" class="banner__container ">
                  <div class="left-side">
                    <p class="title">{{ item.title }}  <!-- Страховка по цене булочки! --></p>
                    <p  class="subtitle">{{ item.description }} <!-- Всего <b>90</b> тенге! --> </p>
                    <!-- <a v-if="item.hasLinkButton == 1" @click="mainBannerClicked(item.buttonLink)" class="secondary-btn btn">
                      {{ item.buttonText }}
                    </a> -->
                  </div>
                </div>
              </div>
            </section>
          </VueSlickCarousel>
        </template>
      </div>
        
      <template v-if="bannerLoading">
        <div>
          <div class="primary-block banner-loading">
            <div class="w-25">
              <h2 class="title skeleton skeleton-text"></h2>
              <h2 class="title skeleton skeleton-text"></h2>
              <div class="gray-btn skeleton w-75"></div>
            </div>
          </div>
        </div>
      </template>
      <section class="insurance-types">
        <template v-if="!insuranceTypeLoading">
          <router-link
              :to="{name: 'insurance.types', params:{id: type.id}}"
              class="insurance-types__item"
              @click.native="productsClicked(type.id)"
              v-for="type in insuranceTypes"
              :key="type.id"
              :style="`background:${type.bgColor ? type.bgColor : '#fff'}`"
          >
            <div class="insurance-types__item__container">

              <h2 class="title">{{ type.mainTitle }}</h2>
              <h5 class="subtitle">{{ type.mainSubtitle }}</h5>
              <div class="gallery">
                <span class="discount-badge" v-if="type.hasDiscount">{{ type.discountValue }}%</span>
                <img :src="type.live_icon" alt="type.title" class="img">
              </div>
            </div>
          </router-link>
        </template>
        <template v-if="insuranceTypeLoading">
          <div class="insurance-types__item"
               v-for="item in 6"
               :key="item"
          >
            <h2 class="title skeleton mb-3 skeleton-text"></h2>
            <div class="icon-box skeleton float-end"></div>
          </div>
        </template>
      </section>
    </div>
  </default-layout>
</template>

<script>

import TheStories from "@/components/TheStories";
import eventLogger from '../../services/eventLogger';

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {mapGetters} from "vuex";

export default {
  name: "Welcome",
  components: {TheStories, VueSlickCarousel},
  data() {
    return {
      insuranceTypes: [],
      insuranceTypeLoading: true,
      banner: null,
      bannerLoading: true,
      options: {
        "arrows": false,
        "dots": true,
        "edgeFriction": 0.35,
        "infinite": true,
        "speed": 700,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "lazyLoad": "ondemand",
        "autoplay": true,
        "autoplaySpeed": 10000,
        // "touchMove": false,
        "draggable": false,
      },

    }
  },
  computed: {
    ...mapGetters({
      authStatus: 'status'
    }),
  },
  mounted() {
    eventLogger('view_landing_screen')
    this.getInsuranceTypes();
    this.getBanner();
  },
  methods: {
    async getInsuranceTypes() {
      try {
        const {data} = await window.axios.get('insurance-types/main');
        this.insuranceTypes = data;
        this.insuranceTypeLoading = false;
      } catch (e) {
        throw e;
      }
    },
    async getBanner() {
      try {
        const {data} = await window.axios.get('main-page-banners');
        this.banner = data;
        this.bannerLoading = false;
      } catch (e) {
        throw e;
      }
    },
    mainBannerClicked(url) {
      eventLogger('select_main_banner');

      let baseUrl = url;

      if (!this.authStatus && this.$store.getters.isNewVersionHB) {
        baseUrl = `${this.$store.getters.hbUrl}${baseUrl}`
      }

      location.href = baseUrl;
    },
    productsClicked(productTypeId) {
      let productName;
      
      switch(productTypeId) {
        case 1:
          productName = 'select_car_insurance';
          break;
        case 2:
          productName = 'select_home_insurance'
          break;
        case 3:
          productName = 'select_health_insurance'
          break;
        case 4:
          productName = 'select_travel_insurance'
          break;
        case 5:
          productName = 'select_fin_insurance'
          break;
        default:
          productName = 'undefined'
          break;
      }

      eventLogger(productName);
    }
  }
}
</script>

<style lang="scss">
@import "../../sass/variables";
.banner-loading {
  min-height: 50vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.banner {
  height: auto;
  margin-bottom: 16px;
  background-size: cover !important;
  background-position-y: bottom !important;
  width: 100vw !important;
  max-width: 768px;

  .banner__container {
    padding: 16px;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    min-height: 50vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
        min-height: 250px;
    .left-side {
      width: 180px;
    }
    .title {
      color: #fff;
      font-size: 18px;
      font-weight: 800;
      line-height: 20px;
    }

    .desc {
      color: #fff;
      font-size: 14px;
      font-weight: 800;
    }

    .btn {
      color: #222;
      font-size: 16px;
    }
  }
}

.welcome-page {
  .insurance-types {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    padding: 0 16px;

    .insurance-types__item {
      background: #FFFFFF;
      box-shadow: 0 12px 16px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      padding: 16px;
      &__container {
        max-width: 180px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .gallery{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
      .discount-badge{
        text-align: center;

        color:white;
        background: #FD2D39;
        /* White */

        border: 1px solid #FFFFFF;
        border-radius: 21px;

        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        //line-height: 12px;

        padding: 2px 8px;
        margin-right: auto;
      }
      .title {
        color: #2A353D;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
      }
      .subtitle {
        color: #2A353D;
        opacity: .5;
        font-size: 13px;
        margin: 5px 0 0;
      }
      .img {
        align-self: flex-end;
        max-width: 100px;
        height: 75px;
        object-fit: contain;
        margin-right: -16px;
        margin-bottom: -16px;
      }
      .text {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: left;
      }
    }
  }
}

@media (max-width: 768px) {
  .welcome-page {
    .insurance-types {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .banner {
    .banner__container {
      /*min-height: 40vh;*/
      min-height: 250px;
    }
  }
  .banner-loading {
    min-height: 40vh;
  }
}
@media (max-width: 555px) {
  .welcome-page {
    .insurance-types {
      grid-template-columns: repeat(2, 1fr);
      .insurance-types__item  {
        img {
          width: 80%;
          height: 60px;
        }
      }
    }
  }
  .banner {
    .banner__container {
      /*min-height: 30vh;*/
      min-height: 160px;
      .left-side {
        width: 120px;
      }
    }
  }
  .banner-loading {
    min-height: 30vh;
  }
}
@media (max-width: 430px) {
  .banner {
    .banner__container {
      min-height: 120px;
      max-height: 120px;
      .left-side {
        width: 120px;
      }
    }
  }
  .banner-loading {
    min-height: 120px;
    max-height: 120px;
  }
}

@media (max-width: 380px) {
  .banner {
    .banner__container {
      min-height: 110px;
      max-height: 110px;
      .left-side {
        width: 120px;
      }
    }
  }
  .banner-loading {
    min-height: 110px;
    max-height: 110px;
  }
}

.banner-slider-wrap{
  margin: 0px 20px;
  margin-bottom: 16px;
  .slick-list{
    border-radius: 12px;
    overflow: hidden;
  }
  .slick-slider{
    padding-bottom: 30px;
  }
  .slick-dots{
    bottom: 0px;
    li{
        width: 8px;
        height: 8px;
        padding: 0px;
        margin: 0 8px;
        button{
          width: 8px;
          height: 8px;
          padding: 0px;
         &:before{
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #D3D7DE;
          opacity: 1;
        }
      }
      &.slick-active{
        button{
           &:before{
            background: #2AA65C;
           }
        }
      }
    }    
  }
}
.banner {
  margin-bottom: 0px;
}

.banner-slider-wrap.redesign .banner .banner__container{
   /* min-height: 120px;
    max-height: 120px;*/
    .left-side{
      .title{
        font-family: 'Manrope';
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: #FFFFFF;
      }
      .subtitle{
        font-family: 'Manrope';
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: #FFFFFF;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
        b{
          color: #fff;
          font-size: 24px;
        }
      }
    }
}


</style>
